
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import UserStore from '@/modules/user/store/user.store';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        LoaderWrapper,
        CustomLoader,
    },
})
export default class AuthPage extends Vue {
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CustomNotificationServiceS) private notificationService!: CustomNotificationService;

    @Prop()
    private token!: string;
    @Prop()
    private postAuth!: boolean;

    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');
    readonly newTokenDetector = (event: { data: { type?: any; token?: any; }; }) => {
        if (event.data?.type === 'new-token') {
            const { token } = event.data;
            if (token) {
                localStorage.setItem('token', token);
                window?.parent?.postMessage({ type: 'post-auth' }, '*');
            }
        }
    };

    beforeMount() {
        window.addEventListener('message', this.newTokenDetector);
        window?.parent?.postMessage({ type: 'auth-loaded' }, '*');
        this.otelService.startSpan({ name: 'login' });
    }

    unmounted() {
        window.removeEventListener('message', this.newTokenDetector);
    }

    async mounted() {
        if (this.postAuth) {
            const storedToken = localStorage.getItem('token') || '';
            this.startAuthentication(storedToken);
        } else if (this.token) {
            this.startAuthentication(this.token);
        }
    }

    async startAuthentication(token: string) {
        const tokenToUse = token || '';
        if (tokenToUse) {
            try {
                // eslint-disable-next-line
                console.log('startAuthentication', tokenToUse);
                await this.authService.authentication(tokenToUse);
                // eslint-disable-next-line
                console.log('startAuthentication', 'finished');
                this.otelService.endSpan({ name: 'login' }, { sendLogs: true });
                this.$router.push('/').catch(() => {}).finally(() => {
                    this.showGreetingsMessage();
                });
            } catch (error) {
                window.location.href = this.authService.loginUrl;
            }
        } else {
            window.location.href = this.authService.loginUrl;
        }
    }

    get isCarPage() {
        return /cr/.test(window.location.hostname);
    }

    showGreetingsMessage() {
        const { user } = this.userService;
        const userName = [user?.firstName, user?.lastName].join(' ');
        const message = !user?.isFirstLogin
            ? this.$tc('user.regularLoginWelcome', 0, [userName])
            : this.$tc('user.firstLoginWelcome', 0, [userName]);
        this.notificationService.pushNotification(message, 'welcome-message');
    }
}
